<template>
  <div id="app-layout">           
   <div class="home__title">
              <h2 class="home__title__bg">CGU et Politique de Confidentialité</h2>
            </div>         
            <div class="pagecontent">
               <div class="privacy">
                  <h3 class="privacy__title">Veuillez lire attentivement les présentes Conditions Générales d’Utilisation.</h3>
                  <p class="privacy__text">Veuillez lire attentivement les présentes Conditions Générales d’Utilisation.
Ce site (le «Site») est édité par LM Créations Numériques, Entreprise Individuelle à Responsabilité Limitée, N° de SIRET 89748949800013, dont le siège est Lieu Dit Paussis 82500 Esparsac (ci-après « LM Créations Numériques», « nous », « notre », « nos »). La responsable de la publication du Site est Me Montagner Angélique.
Le Site est hébergé par la société OVH SAS dont le siège social est situé au 2 rue Kellermann 59100 Roubaix, France.
Le Site fournit à ses utilisateurs un accès aux contenus relatifs aux services proposés par l’office notarial de Me Montagner Angélique et à ses réalisations et notamment des contenus vidéos, des images, des textes, des données et d’autres contenus similaires. L’ensemble des contenus et services fournis sur le Site sont ci-après désignés par le terme « Services ».
Votre utilisation du Site est régie par les présentes Conditions Générales d’Utilisation indépendamment du moyen par lequel vous accédez au Site (par Internet, par l'intermédiaire d’un réseau de type « Wireless Access Protocole » (communément dénommé "WAP"), à travers un réseau mobile, ou via d’autres types de réseaux.
Les présentes Conditions Générales d’Utilisation régissent votre relation avec le Site.
Le Site peut inclure des applications de tiers (telles que définis à la section 5 ci-dessous). Votre utilisation desdites applications est régie par des conditions supplémentaires qui ne sont pas comprises dans les présentes Conditions Générales d’Utilisation et qui sont mises à disposition par leurs fournisseurs.
</p>
                  <h3 class="privacy__title">Article 1 : Acceptation des conditions</h3>
                  <p class="privacy__text">L’accès au Site et l’utilisation de ce dernier implique votre acceptation sans réserve des présentes Conditions Générales d’Utilisation. Nous nous réservons le droit d’apporter des modifications aux présentes Conditions Générales d’Utilisation, modifications qui prendront effet dès leur mise en ligne sur le Site.<br>
Une fois ces modifications mises en ligne sur le Site, votre utilisation du Site emporte acceptation des Conditions Générales d’Utilisation modifiées.
</p>
                  <h3 class="privacy__title">Article 2 : Territoire</h3>
                  <p class="privacy__text">Le Site peut être indisponible dans certains pays.<br>
Vous êtes responsable de votre utilisation du Site. Vous vous engagez notamment à respecter l’ensemble des lois locales et des règles d’usage. Nous nous réservons le droit de restreindre l’accès au site, en tout ou en partie, à toute personne, zone géographique ou juridiction à tout moment et à notre seule discrétion.
</p>
                  <h3 class="privacy__title">Article 3 : Accès au site</h3>
                  <p class="privacy__text">Article 3 : Accès au site
Le site permet d’accéder gratuitement aux services suivants :
<ul class="privacy__list">
<li>Accès aux contenus relatifs aux services proposés par l’office notarial de Me Montagner Angélique</li>
<li>Des actualités et conseils notariaux</li>
<li>Une présentation de l’office</li>
<li>Des moyens de contact et de localisation</li>
</ul>
Le site est accessible gratuitement depuis n’importe où par tout utilisateur disposant d’un accès à Internet. Tous les frais nécessaires pour l’accès aux services (matériel informatique, connexion Internet…) sont à la charge de l’utilisateur.
Pour des raisons de maintenance ou autres, l’accès au site peut être interrompu ou suspendu par l’éditeur sans préavis ni justification.

</p>
                  <h3 class="privacy__title">Article 4 : Règles d’utilisation</h3>
                  <p class="privacy__text">En accédant au Site ou en utilisant celui-ci, vous vous engagez à respecter l’ensemble des législations en vigueur ainsi que les droits des tiers. Vous vous engagez notamment à respecter les règles suivantes (« Les règles d’usage »):
Vous vous engager à ne pas :
<ul class="privacy__list">
<li>Utiliser le Site dans un but contraire aux législations en vigueur.</li>
<li>Entraver le fonctionnement du Site et/ou l’accès à celui-ci notamment par la modification des paramètres du serveur et/ou du réseau ou de manière générale, par la violation des procédures et des règles d’usage relatives aux réseaux.</li>
<li>Restreindre ou empêcher un tiers d’utiliser ou de jouir du Site.</li>
<li>Reproduire, dupliquer, copier, vendre, revendre ou exploiter à des fins commerciales, tout ou une partie du Site.</li>
<li>Utiliser le site pour promouvoir des produits et services sans obtenir notre accord préalable.</li>
<li>Modifier, adapter, traduire, décompiler ou désassembler tout ou partie du Site en dehors des cas expressément autorisé par la loi applicable.</li>
<li>Supprimer les mentions relatives aux droits de la propriété intellectuelle ou tout autre élément du Site (texte, image, son ou autres).</li>
<li>Dupliquer ou reproduire sous forme de « framing » tout ou partie du Site.</li>
<li>Créer une base de données dans le but de télécharger et de sauvegarder systématiquement tout ou partie du contenu du Site.</li>
<li>Utiliser un robot de recherche « spider » ou un aspirateur de site ou un autre dispositif manuel ou automatique permettant d’extraire, indexer les données du Site ou de reproduire ou détourner la structure de navigation ou la présentation du Site, sans notre accord préalable.</li>
</ul>
Nous nous réservons le droit de mettre fin à votre utilisation en cas de violation des présentes conditions.
</p>
                  <h3 class="privacy__title">Article 5 : Droits de propriété intellectuelle</h3>
                  <p class="privacy__text">Nous sommes propriétaires ou avons l'autorisation de publier l’ensemble des contenus que nous mettons à disposition des utilisateurs sur le Site. Ces contenus sont protégés par le droit d’auteur, le droit des marques, le droit des brevets ou toute autre législation en vigueur.<br>
Sauf autorisation expresse et préalable de notre part, vous vous engagez à ne pas reproduire, modifier, louer, emprunter, vendre, distribuer ou créer d'œuvres dérivées basées sur tout ou partie du Site ou tout autre contenu mis à disposition par l'intermédiaire du Site.<br>
En outre, le Site peut contenir des marques et des noms commerciaux ou d’autres éléments protégés appartenant à des tiers. Ces éléments protégés restent la propriété de leurs titulaires de droits respectifs. Tous les brevets, marques, noms commerciaux et / ou autres éléments de propriété intellectuelle sont protégés par les législations en vigueur. Sauf autorisation expresse et préalable, vous vous engagez à ne pas reproduire, modifier, louer, emprunter, vendre, distribuer ou créer d'œuvres dérivées basées sur tout ou partie du Site ou sur tout autre élément mis à disposition par l'intermédiaire du site.<br>
Aucune mention sur le site ne doit être interprétée comme accordant une licence ou un droit d'usage sur un nom commercial, une marque ou tout autre élément protégé sans l’accord écrit et préalable du titulaire des droits.<br>
Veuillez noter que l’utilisation non autorisée des produits et services, incluant sans limitation, l’utilisation du logiciel du service peut engager votre responsabilité civile et pénale (incluant, sans limitation, une possible condamnation à payer des dommages-intérêts).
</p>
 <h4 class="privacy__subtitle">5.1. Crédits photos</h4>
                <p class="privacy__text">Le responsable de publication s’engage à garantir que les photographies et/ou vidéos ajoutées sont libres de droit ou dans le cas contraire à mentionner le nom de l’auteur du document. Les photographies de fond d'écran, de la page d'accueil et de la page Notre Office, ainsi que la vidéo de la page d'accueil sont la propriété de LM Créations Numériques, et publiées avec son autorisation. L'artwork représenté sur la photographie de fond d'écran est réalisé par Elodie Perrier - Lost In Land, et publié avec son autorisation.</p>
<h3 class="privacy__title">Article 6 : Applications de tiers</h3>
                  <p class="privacy__text">Le site peut contenir des logiciels et des services (ou des liens s'y rapportant) édités par des tiers et mis à disposition par nos fournisseurs (ci-après « Application de tiers »).<br>
Dans la mesure où nous ne maitrisons pas les Application de tiers, nous ne pouvons être tenus responsable d’une Application de tiers, incluant, sans limitation, l'exécution, l'exactitude, l'intégrité, la qualité, la légalité, l'utilité, ou la sécurité, ou les droits de propriété intellectuelle concernant l’Application de tiers ou son utilisation.<br>
Nous n'avons aucune obligation de surveiller l’Application de tiers et nous nous réservons le droit de supprimer ou limiter l'accès à tout ou partie de l’Application de tiers à partir du Site et ce, à notre seule discrétion.<br>
La disponibilité d’une Application de tiers sur le Site n’implique pas notre approbation de celle-ci ni notre affiliation à son fournisseur. En outre, votre utilisation de l’Application de tiers peut être soumise à des conditions supplémentaires (telles que les conditions prévues par le fournisseur de l’Application de tiers) qui ne sont pas inclus dans les présentes conditions ni dans la Politique de Confidentialité. Les présentes conditions n’établissent aucune relation légale entre vous et le fournisseur de l’Application de tiers. Aucune mention dans les présentes conditions ne peut être interprétée comme une garantie de notre part à l’égard de toute Application de tiers.</p>
<h3 class="privacy__title">Article 7 : Contenu de tiers</h3>
                  <p class="privacy__text">Le Site peut intégrer certaines fonctionnalités permettant l’accès à des contenus fournis par de tiers (« Contenu de tiers »). Dans la mesure où nous ne maitrisons pas le Contenu de tiers, vous acceptez que nous ne puissions être tenus responsable du Contenu de tiers, incluant, sans limitation, l'exécution, l'exactitude, l'intégrité, la qualité, la légalité, l'utilité, ou la sécurité, ou les droits de propriété intellectuelle concernant le Contenu de tiers ou son utilisation.<br>
Nous n'avons aucune obligation de surveiller le Contenu de tiers et nous nous réservons le droit de supprimer ou limiter l'accès à tout ou partie du Contenu de tiers à partir du Site et ce, à notre seule discrétion.<br>
En outre, votre utilisation du Contenu de tiers peut être soumise à des conditions supplémentaires (telles que les conditions prévues par le fournisseur du Contenu de tiers) qui ne sont pas incluses dans les présentes conditions ni dans la Politique de Confidentialité.<br>
Les présentes conditions n’établissent aucune relation légale entre vous et le fournisseur du Contenu de tiers. Aucune mention dans les présentes conditions ne peut être interprétée comme une garantie de notre à l’égard du Contenu de tiers.
</p>
<h3 class="privacy__title">Article 8 : Liens et flux</h3>
                  <p class="privacy__text">Le site peut contenir des liens vers des sites externes et/ou des flux d’information en provenance d’autres sites. Des sites externes peuvent contenir des liens vers le Site avec ou sans notre consentement. Nous nous réservons le droit de bloquer tout lien vers le Site ou à partir de celui-ci.<br>
Votre utilisation d’un site web externe est effectuée sous votre entière responsabilité. Nous ne pouvons pas être tenus responsables pour une telle utilisation.</p>
<h3 class="privacy__title">Article 9 : Limitation de responsabilité</h3>
                  <p class="privacy__text">Nous n’apportons aucune garantie sur les contenus de tiers et les applications de tiers présents sur le site.
En outre, nous ne pouvons vous garantir la compatibilité d’une application avec le site et vous invitons à vérifier au préalable cette compatibilité.<br>
Vous demeurez seul responsable de l’obtention du logiciel, matériel, et/ou tout autre élément (y compris la connexion à l’internet) nécessaire à l’utilisation du site vous assurer que le logiciel, matériel et service que vous utilisez est compatible avec le site.<br>
Vous êtes seul responsable de l’évaluation des risques liés à l’utilisation du site incluant, sans limitation, l'exactitude, l'intégralité ou l'utilité des applications de tiers, des contenus de tiers, et de l’ensemble des contenus disponibles sur le site.<br>
Nous faisons notre meilleur effort pour maintenir la sécurité du Site. Toutefois, nous ne pouvons pas garantir la sécurité du Site, du Contenu de tiers ou des Applications de tiers. En outre, nous ne garantissons pas le fonctionnement ininterrompu du Site ou des Applications de tiers.<br>
Le Site et/ou les applications des tiers peuvent faire l’objet d’une modification non autorisée effectuée par des tiers.
</p>
<h3 class="privacy__title">Article 10 : Gestion des données personnelles</h3>
                  <p class="privacy__text">Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).</p>
                <h4 class="privacy__subtitle">10.1. Responsables de la collecte des données personnelles</h4>
                <p class="privacy__text">Pour les Données Personnelles collectées dans le cadre d’une demande de contact l’Utilisateur et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est Me Montagner Angélique.<br>
En tant que responsable du traitement des données qu’il collecte, elle s’engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d’établir les finalités de ses traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité.<br>
Chaque fois que l’office notarial de Me Montagner Angélique traite des Données Personnelles, le responsable prend toutes les mesures raisonnables pour s’assurer de l’exactitude et de la pertinence des Données Personnelles au regard des finalités pour lesquelles l’office notarial de Me Montagner Angélique les traite.
</p>
<h4 class="privacy__subtitle">10.2.	Finalité des données collectées</h4>
                <p class="privacy__text">L’office notarial de Me Montagner Angélique est susceptible de traiter tout ou partie des données :
<ul class="privacy__list">
<li>pour rester en contact avec le client qui l’aurait demandé : adresse email, nom, prénom, téléphone</li>
<li>dans le cadre d’analyses statistiques Google Analytics</li>
</ul>
L’office notarial de Me Montagner Angélique ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d’analyses.</p>
 <h4 class="privacy__subtitle">10.3.	Droit d’accès, de rectification et d’opposition</h4>
                <p class="privacy__text">Conformément à la réglementation européenne en vigueur, les Utilisateurs du site de l’office notarial de Me Montagner Angélique disposent des droits suivants :
<ul class="privacy__list">
<li>droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d’effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite  </li>
<li>droit de retirer à tout moment un consentement (article 13-2c RGPD)  </li>
<li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD)  </li>
<li>droit d’opposition au traitement des données des Utilisateurs (article 21 RGPD)  </li>
<li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l’objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD)  </li>
<li>droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui LM Créations Numériques devra communiquer (ou non) ses données à un tiers qu’ils aura préalablement désigné</li>
</ul>
Dès que l’office notarial de Me Montagner Angélique a connaissance du décès d’un Utilisateur et à défaut d’instructions de sa part l’office notarial de Me Montagner Angélique s’engage à détruire ses données, sauf si leur conservation s’avère nécessaire à des fins probatoires ou pour répondre à une obligation légale.<br>
Si l’Utilisateur souhaite savoir comment l’office notarial de Me Montagner Angélique utilise ses Données Personnelles, demander à les rectifier ou s’oppose à leur traitement, l’Utilisateur peut contacter l’office notarial de Me Montagner Angélique à l’adresse suivante : officedelaubian.seignosse@notaires.fr en inscrivant pour objet « Webmaster Données Personnelles ».<br>
Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il souhaiterait que l’office notarial de Me Montagner Angélique corrige, mette à jour ou supprime, en s’identifiant précisément avec une copie d’une pièce d’identité (carte d’identité ou passeport).<br>
Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à l’office notarial de Me Montagner Angélique par la loi, notamment en matière de conservation ou d’archivage des documents. Enfin, les Utilisateurs de l’office notarial de Me Montagner Angélique peuvent déposer une réclamation auprès des autorités de contrôle, et notamment de la CNIL (https://www.cnil.fr/fr/plaintes).</p>
 <h4 class="privacy__subtitle">10.4.	Non-communication des données personnelles</h4>
                <p class="privacy__text">L’office notarial de Me Montagner Angélique s’interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays situé en dehors de l’Union européenne ou reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour autant, l’office notarial de Me Montagner Angélique reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu’ils présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données (RGPD : n° 2016-679).<br>
L’office notarial de Me Montagner Angélique s’engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu’elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident impactant l’intégrité ou la confidentialité des Informations du Client est portée à la connaissance de l’office notarial de Me Montagner Angélique, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections prises. Par ailleurs le Site de l’office notarial de Me Montagner Angélique ne collecte aucune « données sensibles ».<br>
Les Données Personnelles de l’Utilisateur peuvent être traitées par des filiales de  Me Montagner Angélique et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.<br>
Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d’avoir accès aux données des Utilisateurs du Site de Me Montagner Angélique sont uniquement nos collaborateurs.</p>
<h3 class="privacy__title">Article 11. Notification d’incident</h3>
                  <p class="privacy__text">Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d’incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de informations personnelles et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.<br>
                  Aucune information personnelle de l'utilisateur du site l’office notarial de Me Montagner Angélique n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de l’office notarial de Me Montagner Angélique et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur du site l’office notarial de Me Montagner Angélique.<br>
Pour assurer la sécurité et la confidentialité des Données Personnelles l’office notarial de Me Montagner Angélique utilise des réseaux protégés par des dispositifs standards tels que par pare-feu et base de données sécurisée.<br>
Lors du traitement des Données Personnelles, l’office notarial de Me Montagner Angélique comprend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.</p>
<h3 class="privacy__title">Article 12. Liens hypertextes « cookies » et balises (“tags”) internet</h3>
                  <p class="privacy__text">Le site l’office notarial de Me Montagner Angélique contient un certain nombre de liens hypertextes vers d’autres sites, mis en place avec l’autorisation de l’office notarial de Me Montagner Angélique. Cependant, l’office notarial de Me Montagner Angélique n’a pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en conséquence aucune responsabilité de ce fait.<br>
Sauf si vous décidez de désactiver les cookies, vous acceptez que le site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que cela peut réduire ou empêcher l’accessibilité à tout ou partie des Services proposés par le site.</p>
<h4 class="privacy__subtitle">12.1 « COOKIES »</h4>
                <p class="privacy__text">Un « cookie » est un petit fichier d’information envoyé sur le navigateur de l’Utilisateur et enregistré au sein du terminal de l’Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de domaine de l’Utilisateur, le fournisseur d’accès Internet de l’Utilisateur, le système d’exploitation de l’Utilisateur, ainsi que la date et l’heure d’accès. Les Cookies ne risquent en aucun cas d’endommager le terminal de l’Utilisateur.<br>
L’office notarial de Me Montagner Angélique est susceptible de traiter les informations de l’Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à l’office notarial de Me Montagner Angélique d’améliorer le contenu du Site, la navigation de l’Utilisateur et à des fins statistiques internes.<br>
Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l’Utilisateur peut configurer son navigateur pour qu’il lui permette de décider s’il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés dans le terminal ou, au contraire, qu’ils soient rejetés, soit systématiquement, soit selon leur émetteur. L’Utilisateur peut également configurer son logiciel de navigation de manière à ce que l’acceptation ou le refus des Cookies lui soient proposés ponctuellement, avant qu’un Cookie soit susceptible d’être enregistré dans son terminal. L’office notarial de Me Montagner Angélique informe l’Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas toutes disponibles.<br>
Si l’Utilisateur refuse l’enregistrement de Cookies dans son terminal ou son navigateur, ou si l’Utilisateur supprime ceux qui y sont enregistrés, l’Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait également être le cas lorsque le site de l’office notarial de Me Montagner Angélique ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d’affichage ou le pays depuis lequel le terminal semble connecté à Internet.<br>
Le cas échéant, l’office notarial de Me Montagner Angélique décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par l’office notarial de Me Montagner Angélique, résultant du refus de Cookies par l’Utilisateur, et de l’impossibilité pour l’office notarial de Me Montagner Angélique d’enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l’Utilisateur. Pour la gestion des Cookies et des choix de l’Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide du navigateur, qui permettra de savoir de quelle manière l’Utilisateur peut modifier ses souhaits en matière de Cookies.<br>
À tout moment, l’Utilisateur peut faire le choix d’exprimer et de modifier ses souhaits en matière de Cookies. L’office notarial de Me Montagner Angélique pourra en outre faire appel aux services de prestataires externes pour l’aider à recueillir et traiter les informations décrites dans cette section.<br>
Ces types de cookies ne sont déposés sur vos terminaux qu’à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l’application mobile de l’office notarial de Me Montagner Angélique. À tout moment, l’Utilisateur peut néanmoins revenir sur son consentement à ce que l’office notarial de Me Montagner Angélique dépose ce type de cookies.</p>
<h4 class="privacy__subtitle">12.2.	BALISES (“TAGS”) INTERNET</h4>
                <p class="privacy__text">Le site de l’office notarial de Me Montagner Angélique peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d’action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l’intermédiaire d’un partenaire spécialiste d’analyses Web (Google Analytics) susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l’adresse IP de l’Utilisateur) dans un pays étranger.<br>
Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d’accéder au Site, et sur les différentes pages de celui-ci.  
Cette technologie permet à l’office notarial de Me Montagner Angélique d’évaluer les réponses des visiteurs face au Site et l’efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que l’utilisation de ce Site par l’Utilisateur.<br>
Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d’autres sites Internet grâce à ces balises, constituer des rapports sur l’activité du Site à l’attention de l’office notarial de Me Montagner Angélique, et fournir d’autres services relatifs à l’utilisation de celui-ci et d’Internet.</p>
<h3 class="privacy__title">Article 13 : Réclamations et demandes d’information</h3>
                  <p class="privacy__text">Si vous avez une question ou une réclamation concernant le Site, nous vous invitons à nous contacter par e-mail à l'adresse officedelaubian.seignosse@notaires.fr en inscrivant pour objet « webmaster ». Les communications par email n’étant pas sécurisées, nous vous invitons en conséquence à ne pas inclure d’information confidentielle dans votre email.</p>
<h3 class="privacy__title">Article 14 : Contact</h3>
                  <p class="privacy__text">Si vous avez des questions concernant l’interprétation et/ou l'application des présentes conditions, nous vous invitons à nous contacter à l’adresse officedelaubian.seignosse@notaires.fr en inscrivant pour objet « Webmaster CGU ». Les communications par email n’étant pas sécurisées, nous vous invitons en conséquence à ne pas mentionner d’information confidentielle dans vos emails.</p>
<h3 class="privacy__title">Article 15 : Durée du contrat</h3>
                  <p class="privacy__text">Le présent contrat est valable pour une durée indéterminée. Le début de l’utilisation des services du site marque l’application du contrat à l’égard de l’Utilisateur.</p>
<h3 class="privacy__title">Article 16 : loi applicable et attribution de juridiction</h3>
                  <p class="privacy__text">La loi Française sera seule applicable aux présentes CGU.</p>
<h3 class="privacy__title">Article 17 : Médiation de la consommation - Art L 616-1 et R 616-1 du code de la consommationn</h3>
                  <p class="privacy__text">En cas de litige non résolu avec un notaire, vous avez la possibilité de saisir <a href="https://mediateur-notariat.notaires.fr/">le Médiateur du notariat</a>, afin de tenter, avec son aide, de trouver une résolution amiable au conflit.</p>
                </div>  
            </div>          
</div>
</template>

<script>
  export default {
    metaInfo: {
    // Children can override the title.
    title: 'CGU et Politique de Confidentialité - Office Notarial de Me Montagner',
    link: [
    {rel: 'canonical', href: 'https://angelique-montagner.notaires.fr/Privacy'}
   ]
  },
    name: "privacy"
  }
</script>

<style lang="scss">
$tertiary-color: #e5ac5f;

.sitemap {
    padding: 50px;
    &__sous {
        margin-left: 50px;
    }
}

.pagecontent {
    width: 75%;
    margin: auto;
    padding: 20px;
    box-sizing: border-box;
    background-color: rgba(250,250,250,0.95);
    border-radius: 3px;
    @media screen and (max-width: 979px){
        width: 90%;
    }
}

.privacy {
  width: 95%;
  margin: auto;
  text-align: justify;
   @media screen and (max-width: 979px){
        width: 100%;
    }
  &__title {
    margin-bottom: 15px;
  }
  &__text {
    margin-bottom: 30px;
  }
  &__list {
    padding-left: 30px;
    box-sizing: border-box;
    margin: 15px 0;
  }
  &__subtitle {
    padding-left: 25px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
}
</style>